import React from "react";
import { graphql } from "gatsby";
// import Img from "gatsby-image";

import useSetPageData from "../../utils/useSetPageData";
import SEO from "../../components/seo";

import ListView from "../ListView";

export default function WhitePaperList({
  location,
  data: { pageWhitePaperPosts, recentWhitePaperPosts, strapiPnWhitePapers },
  pageContext: { slugToURIMap, baseURI }
}) {
  const { SEO: seo, Hero } = strapiPnWhitePapers;

  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `${Hero[0].main_heading} | Insights`,
          description: seo.description
        }}
      />
      <ListView
        title="Media"
        posts={pageWhitePaperPosts}
        recentPosts={recentWhitePaperPosts}
        slugToURIMap={slugToURIMap}
        baseURI={baseURI}
        hero={Hero[0]}
        icon={Hero[0].insight_image.childImageSharp.fixed}
      />
    </>
  );
}

// alter me!
export const mediaListQuery = graphql`
  query whitePaperListQuery($skip: Int!, $limit: Int!, $recentLimit: Int!) {
    pageWhitePaperPosts: allStrapiPostWhitePapers(
      sort: { fields: [publish_date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { published: { eq: true } }
    ) {
      edges {
        node {
          id
          slug
          heading
          content
          downloadable_file_from_form {
            downloadable_file {
              publicURL
            }
            sales_pitch_one_liner
          }
          media_image: white_papers_media_image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          publish_date
        }
      }
    }
    recentWhitePaperPosts: allStrapiPostWhitePapers(
      sort: { fields: [publish_date], order: DESC }
      limit: $recentLimit
      filter: { published: { eq: true } }
    ) {
      edges {
        node {
          heading
          id
          slug
          publish_date
        }
      }
    }
    strapiPnWhitePapers {
      SEO {
        description
      }
      Hero {
        main_heading
        main_subheading
        insight_image {
          childImageSharp {
            fixed(height: 100, width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
